<template>
  <div class="wameed-dashboard-page-content">
    <page-header
        :title="$t('coupons.add_new_coupons')"
        :sub-title="$t('coupons.add_coupon_subtitle')"
    />
    <section class="wameed-dashboard-page-content_body">
      <b-row class="px-2">
        <b-col lg="12" md="12" class="px-0">
          <b-card no-body class="cursor-pointer wameed-card">
            <b-card-header class="align-items-start tab-title">
              <h5 class="text-reg-18 text-font-main">
                {{ $t('coupons.coupon_details') }}
              </h5>
            </b-card-header>
            <wameed-form refs="" @submitAction="submitCoupon">
              <template slot="form-content" slot-scope="{ invalid }">
                <b-card-body
                    class="position-relative py-5 justify-content-between px-5"
                >
                  <!-- form -->
                  <div class="d-flex flex-wrap py-4">
                    <b-col cols="12" md="12">
                      <text-input
                          v-model="form.name"
                          :label="$t('coupons.coupon_name')"
                          input-classes="text-med-14 text-font-main"
                          :name="$t('coupons.coupon_name')"
                          rules="required|max:200"
                          :placeholder="$t('coupons.coupon_name_placeholder')"
                      />
                    </b-col>
                    <b-col cols="12" md="6">
                      <text-input
                          id="coupon-name"
                          v-model="form.coupon"
                          :label="$t('coupons.coupon_code')"
                          input-classes="text-med-14 text-font-main"
                          :name="$t('coupons.coupon_code')"
                          rules="required|max:10"
                          :placeholder="$t('coupons.coupon_code_placeholder')"
                      />
                    </b-col>
                    <b-col cols="12" md="6">
                      <wameed-dropdown
                          id="coupon-place"
                          v-model="services"
                          :label="$t('coupons.coupon_place')"
                          classes="  text-med-14 w-100"
                          value-classes="  text-med-14  text-font-secondary"
                          :placeholder="$t('coupons.coupon_place_placeholder')"
                          title="title"
                          variant="disable"
                          :items="getAllCouponDropdown"
                          text="title"
                          name="couponPlace"
                          rules="required"
                          multiple
                      />
                    </b-col>
                    <b-col cols="12" md="6">
                      <text-input
                          id="usage-no"
                          v-model="form.usageNo"
                          :label="$t('coupons.usage_no')"
                          input-classes="text-med-14 text-font-main"
                          type="number"
                          name="usageNo"
                          rules="required|integer"
                          :placeholder="$t('coupons.usage_no_placeholder')"
                      />
                    </b-col>

                    <b-col cols="12" md="6">
                      <text-input
                          id="discount-percentage"
                          v-model="form.discountPercentage"
                          input-classes="text-med-14 text-font-main"
                          name="discountPercentage"
                          rules="required|regex:^[1-9]\d*(\.\d+)?$"
                          :label="$t('coupons.discount')"
                          :placeholder="$t('coupons.discount_placeholder')"
                      />
                    </b-col>

                    <b-col cols="12" md="6">
                      <date-picker
                          id="start-date"
                          v-model="form.startDate"
                          name="startDate"
                          rules="required"
                          :label="$t('coupons.start_date')"
                          :placeholder="$t('coupons.start_date_placeholder')"
                          :config="{
                          type: 'string',
                          mask: 'YYYY/MM/DD',
                        }"
                          :popover="{
                          visibility: 'click',
                        }"
                      ></date-picker>
                    </b-col>
                    <b-col cols="12" md="6">
                      <date-picker
                          :disabled="form.startDate ? false : true"
                          id="end-date"
                          v-model="form.endDate"
                          name="endDate"
                          rules="required"
                          :label="$t('coupons.end_date')"
                          :placeholder="$t('coupons.end_date_placeholder')"
                          :config="{
                          type: 'string',
                          mask: 'YYYY/MM/DD',
                        }"
                          :popover="{
                          visibility: 'click',
                        }"
                          :attributes="dateAttributes"
                          :minDate="form.startDate"
                      ></date-picker>
                    </b-col>

                    <b-col cols="12" md="12">
                      <wameed-switch
                          name="check-button"
                          @submitAction="showCouponHolderData"
                          :title="$t('coupons.switch_title')"
                          :subTitle="$t('coupons.switch_subtitle')"
                          sublabelClass="text-medium-16 text-font-secondary mt-4"
                          labelClass=""
                          :checked="form.has_advertiser"
                          classes="text-medium-18 text-font-main"
                      />
                    </b-col>

                    <template v-if="form.has_advertiser">

                      <b-col cols="12" md="6">
                        <text-input
                            v-model="form.advertiser_name"
                            :label="$t('coupons.marketer.name.title')"
                            input-classes="text-med-14 text-font-main"
                            :name="$t('coupons.marketer.name.title')"
                            rules="required|max:10"
                            :placeholder="$t('coupons.marketer.name.placeholder')"
                        />
                      </b-col>
                      <b-col cols="12" md="6">
                        <text-input
                            v-model="form.advertiser_company"
                            :label="$t('coupons.marketer.entity.title')"
                            input-classes="text-med-14 text-font-main"
                            :name="$t('coupons.marketer.entity.title')"
                            rules="required|max:10"
                            :placeholder="$t('coupons.marketer.entity.placeholder')"
                        />
                      </b-col>
                      <b-col cols="12" md="6">
                        <text-input
                            v-model="form.advertiser_phone"
                            type="number"
                            :label="$t('coupons.marketer.phone.title')"
                            input-classes="text-med-14 text-font-main"
                            :name="$t('coupons.marketer.phone.title')"
                            rules="required|max:10"
                            :placeholder="$t('coupons.marketer.phone.placeholder')"
                        />
                      </b-col>
                      <b-col cols="12" md="6">
                        <text-input
                            v-model="form.advertiser_email"
                            :label="$t('coupons.marketer.email.title')"
                            input-classes="text-med-14 text-font-main"
                            :name="$t('coupons.marketer.email.title')"
                            rules="required|email"
                            :placeholder="$t('coupons.marketer.email.placeholder')"
                        />
                      </b-col>
                      <b-col cols="12" md="6">
                        <wameed-dropdown
                            id="coupon-place"
                            v-model="cities"
                            :label="$t('coupons.marketer.city.title')"
                            :placeholder="$t('coupons.marketer.city.placeholder')"
                            classes="  text-med-14 w-100"
                            value-classes="  text-med-14  text-font-secondary"
                            title="title"
                            variant="disable"
                            :items="getAllCouponDropdownCities"
                            text="title"
                            name="couponPlace"
                            rules="required"
                            multiple
                        />
                      </b-col>
                      <b-col cols="12" md="6">
                        <text-input
                            v-model="form.advertiser_commission"
                            :label="$t('coupons.marketer.commission.title')"
                            input-classes="text-med-14 text-font-main"
                            :name="$t('coupons.marketer.commission.title')"

                            rules="required|regex:^[1-9]\d*(\.\d+)?$"
                            :placeholder="$t('coupons.marketer.commission.placeholder')"
                        />
                      </b-col>

                    </template>
                  </div>
                </b-card-body>
                <b-card-footer class="px-4 py-3">
                  <div class="d-flex flex-wrap justify-content-between">
                    <div class="px-4 py-3">
                      <wameed-btn
                          classes="  text-med-14 text-white rounded-10"
                          :title="$t('common.create')"
                          :disabled="invalid"
                          type="submit"
                          variant="main"
                      />
                    </div>
                    <div class="px-4 py-3">
                      <wameed-btn
                          classes="  text-med-14 text-font-secondary rounded-10 "
                          :title="$t('common.cancel')"
                          type="button"
                          variant="gray"
                          @submitAction="cancelCoupon"
                      />
                    </div>
                  </div>
                </b-card-footer>
              </template>
            </wameed-form>
          </b-card>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import WameedForm from '@/components/WameedForm.vue';

import WameedBtn from '@/components/WameedBtn.vue';
import WameedDropdown from '@/components/WameedDropdown.vue';
import {mapActions, mapGetters} from 'vuex';

import TextInput from '@/components/TextInput.vue';
import PageHeader from '@/components/PageHeader.vue';
import Calender from '@/components/Calender.vue';
import DatePicker from '@/components/DatePicker.vue';
import WameedSwitch from "@/components/WameedSwitch";

export default {
  components: {
    WameedSwitch,
    TextInput,
    WameedBtn,
    WameedDropdown,
    WameedForm,
    PageHeader,
    Calender,
    DatePicker,
  },
  data() {
    return {
      couponHolderData: false,
      services: null,
      cities: null,

      form: {
        name: null,
        coupon: null,
        services: null,
        usageNo: null,
        discountPercentage: null,
        startDate: null,
        endDate: null,
        has_advertiser: false,

        advertiser_name: null,
        advertiser_company: null,
        advertiser_phone: null,
        advertiser_email: null,
        cities: null,
        advertiser_commission: null,
      },

    };
  },
  computed: {
    ...mapGetters({
      getAllCouponDropdown: 'getAllCouponDropdown',
      getAllCouponDropdownCities: 'getAllCouponDropdownCities',
    }),
    dateAttributes() {
      const {startDate} = this.form;
      const endDate = this.form.endDate ? this.form.endDate : this.form.startDate;
      return [
        {
          order: 2,
          highlight: {
            start: {
              style: {
                backgroundColor: '#FED029',
                borderRadius: '6px',
              },
            },
            base: {
              style: {
                backgroundColor: '#FED029',
                borderRadius: '6px',
              },
            },
            end: {
              style: {
                backgroundColor: '#FED029',
                borderRadius: '6px',
              },
            },
          },
          dates: {
            start: startDate,
            end: endDate,
          },
        },
      ];
    },
  },
  created() {
    this.loadAllCouponDropdown();
    this.loadAllCouponDropdownCities();
  },
  methods: {
    ...mapActions({
      addCoupon: 'addCoupon',
      loadAllCouponDropdown: 'loadAllCouponDropdown',
      loadAllCouponDropdownCities: 'loadAllCouponDropdownCities',
    }),
    showCouponHolderData(v) {
      this.form.has_advertiser = v;
    },
    cancelCoupon() {
      this.$router.push({name: 'coupons'});
    },
    submitCoupon() {
      this.form.services = this.services.map(item => item.id);
      if (this.cities)
        this.form.cities = this.cities.map(item => item.id);
      this.addCoupon(this.form);
    },
  },
};
</script>
